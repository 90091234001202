const slideshow = document.querySelector(".js-slideshow-single");
const items = slideshow ? [...slideshow.children] : [];

const bulletContainer = document.querySelector(".js-product-gallery-bullets");
const bulletClone = bulletContainer ? [...bulletContainer.children][0].cloneNode(true) : [];

//Gets all the currently visible items for the hero.
function getVisibleItems() {
    return items.filter((item) => !item.classList.contains("hidden"));
}

//Scroll to video button
function scrollToVideoButton() {
    const btn = document.querySelector(".js-scroll-to-video");
    if (btn) {
        btn.addEventListener("click", () => {
            const visibleItems = getVisibleItems();
            const video = visibleItems.find((item) => item.tagName === "VIDEO");
            video.pause();
            video.currentTime = 0;
            slideshow.scrollTo(video.offsetLeft, 0);
            video.play();
        });
    }
}

//Bullet indicators
function createBullets() {
    bulletContainer.innerHTML = "";
    const visibleItems = getVisibleItems();
    const visibleItemsAmount = visibleItems.length;

    for (let i = 0; i < visibleItemsAmount; i++) {
        const bullet = bulletClone.cloneNode(true);
        const bulletImage = bullet.querySelector("img");
        if (bulletImage && visibleItems[i].querySelector("img")) {
            bulletImage.src = visibleItems[i].querySelector("img").src;
        }
        bullet.addEventListener("click", () => {
            slideshow.scrollTo(visibleItems[i].offsetLeft, 0);
        });
        bulletContainer.appendChild(bullet);
    }

    // Initialize Swiper after bullets are created
    window.swiper = new Swiper(".swiper-product-images-bullets", {
        modules: [Navigation, Scrollbar],
        slidesPerView: 6,
        spaceBetween: 5,
        navigation: {
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
            disabledClass: "disabled",
        },
        scrollbar: {
            el: ".swiper-scrollbar",
            hide: false,
        },
    });

    // Emit custom event if needed
    const event = new Event("bulletsCreated");
    document.dispatchEvent(event);
}

function checkActiveBullet() {
    //TOOD: Throttle the scroll event listener
    slideshow.addEventListener("scroll", () => {
        updateBullets();
    });
}

function updateBullets() {
    const currentScroll = slideshow.scrollLeft;
    const scrollIndex = Math.floor(currentScroll / slideshow.offsetWidth);

    const bullets = [...bulletContainer.children];
    bullets.forEach((bullet, index) => {
        if (index === scrollIndex) {
            bullet.classList.add("border-black");
        } else {
            bullet.classList.remove("border-black");
        }
    });
}

export function resetSlideshowScroll() {
    slideshow.scrollTo(0, 0);
    createBullets();
    updateBullets();
}

export default function slideShow() {
    if (slideshow) {
        slideshow.scrollTo(0, 0);
        scrollToVideoButton();

        createBullets();
        checkActiveBullet();
        updateBullets();

        window.addEventListener("resize", createBullets);
    }
}
